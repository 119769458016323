import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';

import { fromGlobalId } from '../../helper';
import { AddProductMutation } from './mutations';
import ProductForm from './ProductForm';

export const handleSubmit = function handleSubmit(form, onSubmitCompleted, newAttributes, formValues) {
  const values = {
    ...formValues,
    partialName: formValues.partialName?.trim(),
    name: formValues.name?.trim(),
    model: formValues.model?.trim(),
    secondModel: formValues.secondModel?.trim(),
    adenCode: formValues.adenCode?.trim(),
    odooCode: formValues.odooCode?.trim(),
    barcode: formValues.barcode?.trim(),
  };

  if (formValues.type === 'bundle' && formValues.bundles) {
    values.bundles = formValues.bundles.map(({ productId, position, quantity }) => ({
      productId: productId.key,
      position,
      quantity,
    }));
  }

  if (formValues.type === 'configurable' && formValues.configurables){
    values.configurableAttributes = formValues.configurableAttributes.map(value => value.key);

    values.configurables = formValues.configurables.map(({ productId, position }) => ({
      productId: productId.key,
      position,
    }));
  }

  const uploadables = {};

  const images = formValues.newImages;
  if (images) {
    const fileList = images.fileList.map(f => f.originFileObj).filter(f => f.size / 1024 / 1024 < 1);

    values.newImages = fileList.map((f, i) => {
      const name = `newImage${i}`;

      uploadables[name] = f;
      return name;
    });
  }

  if (formValues.images) {
    values.images = Object.keys(values.images).map(key => values.images[key]);
  }

  const files = formValues.newFiles;
  if (files) {
    const fileList = files.fileList.map(f => f.originFileObj).filter(f => f.size / 1024 / 1024 < 15);

    values.newFiles = fileList.map((f, i) => {
      const name = `newFiles${i}`;

      uploadables[name] = f;
      return name;
    });
  }

  values.attributes = JSON.stringify(formValues.attributes);

  if (formValues.related) {
    values.related = formValues.related.map(({key}) => key);
  }

  const mutation = {
    environment: this.props.relay.environment,
    variables: { input: values },
    viewer: this.props.viewer,
    onCompleted: (resp) => {
      window.location.href = `/product/${fromGlobalId(resp.addProduct.productEdge.node.id).id}`;
    },
  };

  if (Object.keys(uploadables).length) {
    mutation.uploadables = uploadables;
  }

  AddProductMutation.commit(mutation);
};

class ProductNew extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    relay: PropTypes.shape({
      environment: PropTypes.shape({}).isRequired,
    }).isRequired,
  }

  handleSubmit = handleSubmit.bind(this);

  render() {
    const { viewer } = this.props;

    return (
      <div>
        <Helmet title="New Product" />

        <h1>New Product</h1>
        <ProductForm viewer={viewer} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

export default createFragmentContainer(ProductNew, {
  viewer: graphql`
    fragment ProductNew_viewer on Admin {
      ...ProductForm_viewer
    }
  `,
});

import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get, groupBy, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { Button, message, Table, TreeSelect } from 'antd';

import { isStateOrAreaMgr } from '~/helper';
import { DatePicker } from '~/components/form';
import { DatePickerRanges } from './helper'

const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;

const entityName = 'Dispatch Report';
const graphqlFieldName = 'dispatchReport';

const PERFORMANCE_THRESHOLD = 95;

class DispatchReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      roles: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
      dispatchReport: PropTypes.arrayOf(PropTypes.object),
      stores: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      route: PropTypes.shape({
        prepareVariables: PropTypes.func.isRequired,
      }).isRequired
    }).isRequired,
  }

  constructor(props) {
    super(props);

    // get default date range from router
    const { match } = this.props;
    const { filterBy: [ { filter: dateFilter } ] } = match.route.prepareVariables();
    const initialDispatchResult = get(this.props.viewer, 'dispatchReport', []);

    this.dateRange = dateFilter.split(',').map(d => moment(d));
    this.fetchedDateRange = this.dateRange;

    let treeValue = ["all"];

    const roles = get(props, 'viewer.roles.edges', []).map(({ node }) => node);
    if (isStateOrAreaMgr(roles)) {
      treeValue = roles.flatMap((r) => {
        const defaultValues = get(r, "defaultValues", []).find(({ name }) => name === graphqlFieldName);
        return get(defaultValues, "values.treeValue", []);
      });
    }

    this.state = {
      loading: false,
      treeValue,
      filteredShipping: initialDispatchResult,
      expandedRowKeys: [],
      expandAll: true,
    };
  }

  onDateChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  onFilterChange = (locations = [], states = []) => {
    const shipping = get(this.props.viewer, 'dispatchReport', []);
    let filteredShipping = locations.length > 0 ? shipping.filter(item => locations.includes(item.store)) : shipping;
    filteredShipping = states.length > 0 ? filteredShipping.filter(item => states.includes(item.state)) : filteredShipping;

    this.setState({filteredShipping, expandedRowKeys: [], expandAll: true});
  }

  onTreeChange = (value) => {
    const shipping = get(this.props.viewer, 'dispatchReport', []);

    const filteredShipping = shipping.filter(({ store, state }) => {
      if (isEqual(value, ["all"])) {
        return true;
      }
      return value.includes(store) || value.includes(state);
    });

    this.setState({
      treeValue: value,
      filteredShipping,
      expandedRowKeys: [],
      expandAll: true,
    });
  }

  handleExpand = () => {
    this.setState({expandAll: !this.state.expandAll});

    if (this.state.expandAll) {
      const allRowKeys = this.state.filteredShipping.map(record => record.id);
      this.setState({expandedRowKeys: allRowKeys,});
    } else {
      this.setState({expandedRowKeys: []});
    }
  }

  refetch = (date, { force = false } = {}) => {
    const filter = [date[0].startOf('day').local().format(), date[1].endOf('day').local().format()].join(",");

    if (!force && this.fetchedDateRange && this.fetchedDateRange[0].isSame(date[0]) && this.fetchedDateRange[1].isSame(date[1])) {
      return;
    }

    const refetchVariables = {
      filterBy: [
        {
          field: "inserted_at",
          filter,
          filterType: "text",
          type: "inRange",
        },
      ]
    };

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error)=> {
        if (error) {
          const e = get(error, '[0].message', 'Shit happened');
          message.error(e);

          this.setState({filteredShipping: []})
        } else {
          const { treeValue } = this.state;
          this.onTreeChange(treeValue);
        }

        this.fetchedDateRange = date;

        this.setState({
          loading: false,
        });

      },
      refetchVariables
    );
  }

  expandedRowRender = (record) => {
    const styles = {
      "0 - 1 Day": 'ant-alert-success',
      "2 - 3 Days": 'ant-alert-success',
      "4 - 5 Days": 'ant-alert-warning',
      "6 - 10 Days": 'ant-alert-error',
      "11 - 19 Days": 'ant-alert-error',
      "20+ Days": 'ant-alert-error',
      "Not Dispatched": '',
    };

    const nestedColumns = [
      { title: <strong>Days Took</strong>, dataIndex: 'range', key: 'range' },
      { title: <strong>Number of Orders</strong>, dataIndex: 'order_num', key: 'order_num' },
      { title: <strong>Percentage</strong>,
        dataIndex: 'percentage',
        key: 'percentage',
        render: percentage => {
          return (
            <span>
              {Number(percentage).toFixed(2)}%
            </span>
          );
        }
      },
    ];

    const orderNames = (dispatchBreakdown) => (
      <ul>
        {dispatchBreakdown.orders.map(order => (
          <li key={order}>
            <a href={`/sales/order/${order.match(/\d+/)[0]}`} target="_blank" rel="noopener noreferrer">
              {order}
            </a>
          </li>
        ))}
      </ul>
    );

    return (
      <Table
        dataSource={record.dispatchBreakdown.map((item, index) => ({ ...item, key: index }))}
        columns={nestedColumns}
        pagination={false}
        rowClassName={(dispatchBreakdown) => {
          return styles[dispatchBreakdown.range]
        }}
        size='small'
        expandable={{
          expandedRowRender: dispatchBreakdown => dispatchBreakdown.orders.length > 0 ? orderNames(dispatchBreakdown) : null,
          rowExpandable: dispatchBreakdown => dispatchBreakdown.orders.length > 0
        }}
      />
    );
  };

  render() {
    const { viewer } = this.props;
    const { filteredShipping, loading, expandedRowKeys, expandAll, treeValue } = this.state;

    const stores = get(viewer, 'stores.edges', []);
    const grouped = groupBy(stores, ({ node }) => node.state);
    const groupedKeys = Object.keys(grouped);

    const treeData = [{
      value: 'all',
      title: 'All Stores',
      children: groupedKeys.sort().map((k) => ({
        value: k,
        title: k,
        children: grouped[k].map(({ node }) => ({ value: node.name, title: node.name }))
      })),
    }];

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <RangePicker
          defaultValue={this.dateRange}
          ranges={DatePickerRanges()}
          onChange={this.onDateChange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.refetch(this.dateRange);
            }
          }}
        />

        <div style={{ display: 'block', marginTop: '10px' }}>
          <TreeSelect
            style={{ maxWidth: '400px', width: '100%' }}
            treeDefaultExpandAll
            treeData={treeData}
            value={treeValue}
            onChange={this.onTreeChange}
            showCheckedStrategy={SHOW_PARENT}
            placeholder="Select Store(s)"
            filterTreeNode={(input, treeNode) => treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            treeCheckable
            allowClear
          />

          <Button style={{ marginLeft: '20px', minWidth: '50px' }} onClick={this.handleExpand}>
            {expandAll ? 'Expand All' : 'Collapse All'}
          </Button>
        </div>

        <Table
          dataSource={filteredShipping}
          sticky
          rowKey={record => record.id}
          pagination={{
              position: 'both',
              defaultPageSize: 1000,
            }}
          style={{marginTop: '20px'}}
          loading={loading}
          expandable={{
            expandedRowRender: this.expandedRowRender,
            expandedRowKeys,
            onExpandedRowsChange: (keys) => this.setState({ expandedRowKeys: keys }),
          }}
          columns={
              [
                { title: <strong>Store</strong>,
                  dataIndex: 'store',
                  key: 'store',
                  align: 'center',
                  sorter: (a, b) => a.store.localeCompare(b.store),
                },
                { title: <strong>State</strong>,
                  dataIndex: 'state',
                  key: 'state',
                  width: '15%',
                  align: 'center',
                  sorter: (a, b) => a.state.localeCompare(b.state),
                },
                {
                  title: <strong>Number of Orders</strong>,
                  dataIndex: 'total',
                  key: 'total',
                  align: 'center',
                  sorter: (a, b) => a.total - b.total,
                },
                {
                  title: <strong>3-Day Percentage</strong>,
                  dataIndex: 'overallPct',
                  key: 'overallPct',
                  align: 'center',
                  sorter: (a, b) => a.overallPct - b.overallPct,
                  render: overallPct => {
                    const style = {color: overallPct < PERFORMANCE_THRESHOLD ? 'red' : 'black'};
                    return (
                      <span style={style}>
                        {Number(overallPct).toFixed(2)}%
                      </span>
                    );
                  }
                },
              ]
            }
        />
      </div>
    );
  }
}

export default createRefetchContainer(DispatchReport, {
    viewer: graphql`
    fragment DispatchReport_viewer on Admin {
      roles(first: 99){
        edges {
          node {
            name
            defaultValues
          }
        }
      }
      dispatchReport(orderBy: $orderBy, filterBy: $filterBy)
      stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
            state
            branchCode
            distributionHub
          }
        }
      }
    }
  `,
  },
  graphql`
    query DispatchReportRefetchQuery($orderBy: OrderBy, $filterBy: [FilterBy]) {
      viewer {
        ...DispatchReport_viewer
      }
    }
  `,
);

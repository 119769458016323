import React from 'react';
import PropTypes from 'prop-types';
import {
  createRefetchContainer,
  graphql,
} from 'react-relay';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { DatePicker, message, Select, Table, Tabs } from 'antd';
import moment from 'moment-timezone';

const { Option } = Select;
const { TabPane } = Tabs;

const entityName = 'Sales Target Report';

class SalesTargetReport extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      salesTargetReport: PropTypes.shape({
        stores_data: PropTypes.arrayOf(PropTypes.object),
        salesman_data: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    relay: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.dateRange = '';
    this.fetchedDateRange = '';

    this.state = {
      activeKey: "Store",
      loading: false,
      locations: [],
      states: [],
      staff: [],
      filteredStoreData: [],
      filteredSalesmanData: [],
    };
  }

  onDateChange = (dateRange) => {
    this.dateRange = dateRange;
  }

  onFilterChange = (locations = [], states = [], staff = []) => {
    const { viewer } = this.props;
    const StoreData = get(viewer, 'salesTargetReport.stores_data', []);
    const SalesmanData = get(viewer, 'salesTargetReport.salesman_data', []);

    const filteredSalesmanData = SalesmanData.filter(item =>
      (locations.length === 0 || locations.includes(item.branch_name)) &&
      (states.length === 0 || states.includes(item.state)) &&
      (staff.length === 0 || staff.includes(item.staff_name))
    );

    const filteredStoreData = StoreData.filter(item => states.length === 0 || states.includes(item.state));

    this.setState({ filteredStoreData, filteredSalesmanData });
  }

  getMonthsBetween = ([startMonth, endMonth]) => {
    const months = [];
    const current = moment(startMonth);
    const endDate = moment(endMonth);

    while (current.isSameOrBefore(endDate, 'month')) {
      months.push(current.format('YYYY-MM'));
      current.add(1, 'month');
    }

    return months;
  }


  StoreColumn = [
    {
      title: 'Year_Month',
      dataIndex: 'year_month',
      key: 'year_month',
      sorter: (a, b) => a.year_month.localeCompare(b.year_month),
    },
    {
      title: 'Branch Name',
      dataIndex: 'branch_name',
      key: 'branch_name',
      sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: 'Daily Target',
      dataIndex: 'daily_target',
      key: 'daily_target',
      render: text => `$${(parseFloat(text) / 1000).toFixed(2)}k`,
      sorter: (a, b) => parseFloat(a.daily_target) - parseFloat(b.daily_target),
    },
    {
      title: 'Monthly Target',
      dataIndex: 'monthly_target',
      key: 'monthly_target',
      render: text => `$${(parseFloat(text) / 1000).toFixed(2)}k`,
      sorter: (a, b) => parseFloat(a.monthly_target) - parseFloat(b.monthly_target),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      render: text => `$${(parseFloat(text) / 1000).toFixed(2)}k`,
      sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
    },
    {
      title: 'Percentage',
      dataIndex: 'pct',
      key: 'pct',
      render: text => `${(parseFloat(text) * 100).toFixed(2)}%`,
      sorter: (a, b) => parseFloat(a.pct) - parseFloat(b.pct),
    },
    {
      title: 'Meet',
      dataIndex: 'meet',
      key: 'meet',
      sorter: (a, b) => parseFloat(a.meet) - parseFloat(b.meet),
    },
  ];

  additionalColumns = [
    {
      title: 'Staff Name',
      dataIndex: 'staff_name',
      key: 'staff_name',
      sorter: (a, b) => a.staff_name.localeCompare(b.staff_name),
    },
    {
      title: 'Days Worked',
      dataIndex: 'days_worked',
      key: 'days_worked',
      sorter: (a, b) => parseFloat(a.days_worked) - parseFloat(b.days_worked),
    },
  ];

  SalesmanColumns = [...this.additionalColumns, ...this.StoreColumn];

  refetch = (date) => {
    if (this.fetchedDateRange && this.fetchedDateRange === date) {
      return;
    }

    const monthRange = date.join(',');

    const refetchVariables = {
      filterBy: [
        {
          field: "inserted_at",
          filter: monthRange,
          filterType: "text",
          type: "inRange",
        },
      ]
    };

    const loading = true;
    this.setState({loading});

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error)=> {
        if (error) {
          const e = get(error, '[0].message', 'Shit happened');
          message.error(e);

          this.setState({filteredSalesmanData: [], filteredStoreData: []});
        } else {
          const { locations, states, staff } = this.state;
          this.onFilterChange(locations, states, staff);
        }

        this.fetchedDateRange = date;

        this.setState({
          loading: false,
        });

      },
      refetchVariables
    );
  }

  renderSelectStates = () => {
    const STATES = () => {
      const country = process.env.COUNTRY;

      if ( country === "NZ" ) {
        return ['NORTH ISLAND', 'SOUTH ISLAND'];
      }

      return ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
    }

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={states => {
          this.setState({states});
          this.onFilterChange(this.state.locations, states, this.state.staff);
        }}
        value={this.state.states}
        placeholder="Select a state"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.locations.length>0}
      >
        {STATES().map(node => (
          <Option key={node} value={node}>{node}</Option>
        ))}
      </Select>
    )
  }

  renderSelectStores = () => {
    const StoreData = get(this.props.viewer, 'salesTargetReport.stores_data', []);
    const stores = [...new Set(StoreData.map(item => item.branch_name))].sort((a, b) => a.localeCompare(b));

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={locations => {
          this.setState({locations});
          this.onFilterChange(locations, this.state.states, this.state.staff);
        }}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.locations}
        placeholder="Select a store"
        style={{marginLeft: '20px', minWidth: '200px'}}
        disabled={this.state.states.length>0}
      >
        {stores.map((node) => {
          return <Option key={node} value={node}>{node}</Option>
        })}
      </Select>
    )
  }

  renderSelectStaff = () => {
    const SalesmanData = get(this.props.viewer, 'salesTargetReport.salesman_data', []);
    const staffs = [...new Set(SalesmanData.map(item => item.staff_name))].sort((a, b) => a.localeCompare(b));;

    return (
      <Select
        allowClear
        mode="multiple"
        showSearch
        onChange={staff => {
          this.setState({staff});
          this.onFilterChange(this.state.locations, this.state.states, staff);
        }}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        value={this.state.staff}
        placeholder="Select a staff"
        style={{marginLeft: '20px', minWidth: '200px'}}
      >
        {staffs.map((node) => {
          return <Option key={node} value={node}>{node}</Option>
        })}
      </Select>
    )
  }

  render() {
    const { activeKey, filteredSalesmanData, filteredStoreData,loading } = this.state;
    const rowStyles = {
      1: 'ant-alert-success',
      0: 'ant-alert-error',
    };

    return (
      <div>
        <Helmet title={`${entityName} List`} />
        <h1>{entityName}</h1>

        <DatePicker.RangePicker
          picker="month"
          onChange={this.onDateChange}
          onOpenChange={open => {
            // if popup panel is closing
            if (!open) {
              this.dateRange = this.dateRange.map(date => {
                  return date.format('YYYY-MM');
              });

              this.dateRange = this.getMonthsBetween(this.dateRange)
              this.refetch(this.dateRange)
            }
          }}
        />

        {this.renderSelectStates()}

        {activeKey ==='Salesman' && this.renderSelectStores()}

        {activeKey ==='Salesman' && this.renderSelectStaff()}

        <Tabs activeKey={activeKey} onChange={(key) => { this.setState({ activeKey: key }); }}>
          <TabPane tab="Store" key="Store">
            <Table
              dataSource={filteredStoreData.map((item, index) => ({ ...item, key: index }))}
              columns={this.StoreColumn}
              loading={loading}
              pagination={{ position: "both", pageSize: 100 }}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
            />
          </TabPane>

          <TabPane tab="Salesman" key="Salesman">
            <Table
              dataSource={filteredSalesmanData.map((item, index) => ({ ...item, key: index }))}
              columns={this.SalesmanColumns}
              loading={loading}
              pagination={{ position: "both", pageSize: 100 }}
              scroll={{ x: true }}
              rowClassName={(record) => {
                return rowStyles[record.meet]
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default createRefetchContainer(
    SalesTargetReport, {
    viewer: graphql`
    fragment SalesTargetReport_viewer on Admin {
      salesTargetReport(filterBy: $filterBy)
    }
  `,
  },
  graphql`
    query SalesTargetReportRefetchQuery($filterBy: [FilterBy]) {
      viewer {
        ...SalesTargetReport_viewer
      }
    }
  `,
);

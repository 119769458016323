import React from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';
import moment from 'moment-timezone';
import { get, mergeWith, uniq } from 'lodash';
import { getRoles, isStateOrAreaMgr } from '~/helper';
import { TYPE } from './BySubcategoryReport'

const routes = (
  <Route
    path="/report"
  >
    <Route
      path="/revenue-report"
      getComponent={() => (
        import(/* webpackChunkName: "RevenueReport" */'./RevenueReport').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
      )}
      query={graphql`
        query routes_RevenueReport_Query($filterBy: [FilterBy], $type: String) {
          viewer {
            ...ByMinMaxReport_selectFragment_viewer
            ...RevenueReport_viewer
          }
        }
      `}
    />

    <Route
      path="/sales-report"
      getComponent={() => (
        import(/* webpackChunkName: "SalesReport" */'./SalesReport').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SalesReport_Query ($orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...SalesReport_viewer
          }
        }
      `}
      prepareVariables={(params, router) => {
        let startDate = moment().startOf('day').utc().format();
        let endDate = moment().endOf('day').utc().format();

        const rangeFilter = {
          field: "inserted_at",
          filter: [startDate, endDate].join(","),
          filterType: "text",
          type: "inRange",
        };

        const locationFilter = {
          field: "shipments.lines.location_code",
          filter: "[]",
          filterType: "text",
          type: "in",
        }

        if (router) {
          const { location: { query } } = router;

          const { range, loc } = query;
          if (range) {
            const dates = range.split(",");
            if (dates.length === 2) {
              startDate = moment(dates[0]).startOf('day').local().format();
              endDate = moment(dates[1]).endOf('day').local().format();
              rangeFilter.filter = [startDate, endDate].join(",");
            }
          }

          if (loc) {
            locationFilter.filter = loc;
          }
        }

        return {
          count: 2147483647,
          filterBy: [rangeFilter, locationFilter]
        };
      }}
    />

    <Route
      path="/dispatch-report"
      getComponent={() => (
        import(/* webpackChunkName: "DispatchReport" */'./DispatchReport').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_DispatchReport_Query ($orderBy: OrderBy, $filterBy: [FilterBy]) {
          viewer {
            ...DispatchReport_viewer
          }
        }
      `}
      prepareVariables={() => {
        const startDate = moment().startOf('day').utc().format();
        const endDate = moment().endOf('day').utc().format();

        const rangeFilter = {
          field: "inserted_at",
          filter: [startDate, endDate].join(","),
          filterType: "text",
          type: "inRange",
        };

        return {
          count: 2147483647,
          filterBy: [rangeFilter]
        };
      }}
    />

    <Route
      path="/sales-trend"
      getComponent={() => (
        import(/* webpackChunkName: "SalesTrend" */'./SalesTrend').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SalesTrend_Query ($filterBy: [FilterBy]) {
          viewer {
            ...SalesTrend_viewer
          }
        }
      `}
      prepareVariables={() => ({
        count: 2147483647,
        filterBy: {
          field: "inserted_at",
          filter: [moment().startOf('day').subtract(7,'d').utc().format(), moment().endOf('day').utc().format()].join(","),
          filterType: "text",
          type: "inRange",
        }
      })}
    />

    <Route
      path="/by-min-max"
      getComponent={() => (
        import(/* webpackChunkName: "ByMinMaxReport" */'./ByMinMaxReport').then(module => module.default)
          .catch(() => {
            window.location.reload();
          })
      )}
      query={graphql`
        query routes_ByMinMaxReport_Query($filterBy: [FilterBy], $orderBy: OrderBy, $groupBy: String) {
          viewer {
            ...ByMinMaxReport_selectFragment_viewer
            ...ByMinMaxReport_reportFragment_viewer
          }
        }
      `}
      prepareVariables={() => ({ filterBy: null, orderBy: null, groupBy: '' })}
    />

    <Route
      path="/by-subcategory"
      getComponent={() => (
        import(/* webpackChunkName: "BySubcategory" */'./BySubcategory').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_BySubcategoryReport_Query($filterBy: [FilterBy], $orderBy: OrderBy, $type: String, $dataSource: String) {
          viewer {
            ...BySubcategory_viewer
          }
        }
      `}
      prepareVariables={(params, router) => {
        const roles = getRoles();
        const isMgr = isStateOrAreaMgr(roles);

        const startDate = moment().subtract(1, 'days').startOf('day').utc().format();
        const endDate = moment().subtract(1, 'days').endOf('day').utc().format();

        const filterBy = [{
          field: "inserted_at",
          filter: [startDate, endDate].join(","),
          filterType: "text",
          type: "inRange",
        }];

        const orderBy = {
          field: "row_total",
          direction: "desc",
        };

        let dataSource = 'online_instore';

        const variables = get(router, "location.query.params", null)
        if (variables) {
          const decodedVariables = JSON.parse(decodeURIComponent(variables));
          const { dateRange, order, subcategoryId, custCode, categoryId, brandId, source, byBrand, byStore, byOper, byBrandFilter, operators, locations, byCust, byUnlistedProduct, dateGpCreated, byDateGpCreated } = decodedVariables;

          const type = TYPE({ dataSource: source, byBrand, byBrandFilter, byStore, byOper, byCust });

          if (source) {
            dataSource = source;
          }

          if (dateRange) {
            filterBy[0].filter = dateRange.join(",");
          }

          if ( dateGpCreated ) {
            const dateGPRangeFilter = [moment(dateGpCreated[0]).startOf('day').utc().format(), moment(dateGpCreated[1]).endOf('day').utc().format()].join(",");

            filterBy.push({
              field: "date_GP_created",
              filter: dateGPRangeFilter,
              filterType: "text",
              type: "inRange",
            })
          }

          if (order) {
            orderBy.field = order;
          }

          if (subcategoryId) {
            filterBy.push({
              field: "subcategory_id",
              filter: subcategoryId,
              filterType: "text",
              type: "equals",
            })
          }

          if (categoryId) {
            filterBy.push({
              field: "category_id",
              filter: categoryId,
              filterType: "text",
              type: "equals",
            })
          }

          if (brandId) {
            filterBy.push({
              field: "brand_id",
              filter: brandId,
              filterType: "text",
              type: "equals",
            })
          }

          if (custCode) {
            filterBy.push({
              field: "cust_code",
              filter: custCode,
              filterType: "text",
              type: "equals",
            })
          }

          if (locations.length > 0) {
            filterBy.push({
              field: "branches",
              filter: locations.join(','),
              filterType: "text",
              type: "equals",
            })
          }

          if (operators.length > 0) {
            filterBy.push({
              field: "operators",
              filter: operators.join(','),
              filterType: "text",
              type: "equals",
            })
          }

          if (byUnlistedProduct) {
            filterBy.push({
              field: "unlisted_product",
              filter: byUnlistedProduct.toString(),
              filterType: "boolean",
              type: "equals",
            })
          }

          return {
            count: 2147483647,
            filterBy,
            type,
            orderBy,
            dataSource,
            byBrand,
            byStore,
            byCust,
            byOper,
            byBrandFilter,
            byUnlistedProduct,
            byDateGpCreated,
            operators,
            locations,
            custCode,
          };
        }

        let type = null;

        if (isMgr) {
          const customizer = (objValue, srcValue) => {
            if (Array.isArray(objValue) && Array.isArray(srcValue)) {
              return uniq(objValue.concat(srcValue));
            }
            return undefined;
          };

          const values = roles.reduce((acc, r) => {
            const defaultValues = get(r, "defaultValues", []).find(({ name }) => name === "barChartReport");
            const v = get(defaultValues, "values", {});

            return mergeWith(acc, v, customizer);
          }, {});

          type = TYPE({ byStore: true });
          Object.entries(values).forEach(([field, filter]) => {
            filterBy.push({
              field,
              filter: filter.join(","),
              filterType: "text",
              type: "equals",
            });
          });
        }

        return {
          count: 2147483647,
          filterBy,
          type,
          orderBy,
          dataSource,
          byBrand: false,
        };
      }}
    />

    <Route
      path="/sales-target"
      getComponent={() => (
        import(/* webpackChunkName: "SalesTarget" */'./SalesTarget').then(module => module.default)
        .catch(() => {
          window.location.reload();
        })
      )}
      query={graphql`
        query routes_SalesTarget_Query($filterBy: [FilterBy]) {
          viewer {
            ...SalesTargetReport_viewer
          }
        }
      `}
    />
  </Route>
);

export default routes;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BySubcategory_viewer$ref = any;
export type FilterBy = {|
  field: string,
  filter: string,
  filterType: string,
  type: string,
|};
export type OrderBy = {|
  direction: string,
  field: string,
|};
export type routes_BySubcategoryReport_QueryVariables = {|
  filterBy?: ?$ReadOnlyArray<?FilterBy>,
  orderBy?: ?OrderBy,
  type?: ?string,
  dataSource?: ?string,
|};
export type routes_BySubcategoryReport_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BySubcategory_viewer$ref
  |}
|};
export type routes_BySubcategoryReport_Query = {|
  variables: routes_BySubcategoryReport_QueryVariables,
  response: routes_BySubcategoryReport_QueryResponse,
|};
*/


/*
query routes_BySubcategoryReport_Query(
  $filterBy: [FilterBy]
  $orderBy: OrderBy
  $type: String
  $dataSource: String
) {
  viewer {
    ...BySubcategory_viewer
    id
  }
}

fragment BySubcategory_viewer on Admin {
  barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource)
  roles(first: 99) {
    edges {
      node {
        name
        defaultValues
        id
      }
    }
  }
  brands(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  subcategories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
      }
    }
  }
  stores(first: 9999, orderBy: {field: "name", direction: "asc"}) {
    edges {
      node {
        id
        name
        branchCode
        distributionHub
      }
    }
  }
  staff
  ...SearchCustomer_viewer
}

fragment SearchCustomer_viewer on Admin {
  getCustomerSuggestion(query: "")
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dataSource"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "asc",
      "field": "name"
    }
  }
],
v7 = [
  (v5/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BySubcategoryReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BySubcategory_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_BySubcategoryReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "dataSource",
                "variableName": "dataSource"
              },
              {
                "kind": "Variable",
                "name": "filterBy",
                "variableName": "filterBy"
              },
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderBy"
              },
              {
                "kind": "Variable",
                "name": "type",
                "variableName": "type"
              }
            ],
            "kind": "ScalarField",
            "name": "barChartReport",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 99
              }
            ],
            "concreteType": "AdminRoleConnection",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultValues",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "roles(first:99)"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "BrandConnection",
            "kind": "LinkedField",
            "name": "brands",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrandEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "brands(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "CategoryConnection",
            "kind": "LinkedField",
            "name": "categories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "categories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "SubcategoryConnection",
            "kind": "LinkedField",
            "name": "subcategories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubcategoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subcategory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "subcategories(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "StoreConnection",
            "kind": "LinkedField",
            "name": "stores",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Store",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "branchCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "distributionHub",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "stores(first:9999,orderBy:{\"direction\":\"asc\",\"field\":\"name\"})"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "staff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "query",
                "value": ""
              }
            ],
            "kind": "ScalarField",
            "name": "getCustomerSuggestion",
            "storageKey": "getCustomerSuggestion(query:\"\")"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "647866893164d58e1140fd364f64b7ca",
    "id": null,
    "metadata": {},
    "name": "routes_BySubcategoryReport_Query",
    "operationKind": "query",
    "text": "query routes_BySubcategoryReport_Query(\n  $filterBy: [FilterBy]\n  $orderBy: OrderBy\n  $type: String\n  $dataSource: String\n) {\n  viewer {\n    ...BySubcategory_viewer\n    id\n  }\n}\n\nfragment BySubcategory_viewer on Admin {\n  barChartReport(filterBy: $filterBy, orderBy: $orderBy, type: $type, dataSource: $dataSource)\n  roles(first: 99) {\n    edges {\n      node {\n        name\n        defaultValues\n        id\n      }\n    }\n  }\n  brands(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  categories(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  subcategories(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  stores(first: 9999, orderBy: {field: \"name\", direction: \"asc\"}) {\n    edges {\n      node {\n        id\n        name\n        branchCode\n        distributionHub\n      }\n    }\n  }\n  staff\n  ...SearchCustomer_viewer\n}\n\nfragment SearchCustomer_viewer on Admin {\n  getCustomerSuggestion(query: \"\")\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8d438a58ff33dcc87bbd7686debdb86';

module.exports = node;
